import { makeAutoObservable } from "mobx";

import AuthService from "../service/AuthService";

export default class Store {
  isAuth = false;
  confirmRequest = false;
  userParams = false;
  isLoading = false;
  error = "";

  constructor() {
    makeAutoObservable(this);
  }

  setAuth(bool) {
    this.isAuth = bool;
  }

  setConfirmRequest(bool) {
    this.confirmRequest = bool;
  }
  // setUser(user) {
  //   this.user = user;
  // }

  setUserParams(bool) {
    this.userParams = bool;
  }

  setAuthError(error) {
    this.error = error;
  }

  setIsLoading(bool) {
    this.isLoading = bool;
  }

  // New method to process user data
  processUserData(userData, accessToken) {
    if (userData.isVerifyedAccount === false) {
      this.setConfirmRequest(true);
    } else {
      localStorage.setItem("token", accessToken);
      if (userData.isCompleteCustomerReg === true) {
        localStorage.setItem("userParams", true);
        this.setUserParams(true);
      }
      this.setAuth(true);
      this.setAuthError(false);
    }
  }

  async login(email, password) {
    try {
      const response = await AuthService.login(email, password);
      const userData = response.data.data.user;
      const accessToken = response.data.data.accessToken;
      this.processUserData(userData, accessToken);
    } catch (e) {
      if (e instanceof Error) {
        console.log(e.message);
        this.setAuthError(e.response?.data?.message ?? "Неизвестная ошибка");
      }
    }
  }

  async registration(type, email, password) {
    try {
      const response = await AuthService.registration(type, email, password);
      if (response.status === 200) {
        this.setConfirmRequest(true);
        this.setAuthError(false);
      }
    } catch (e) {
      console.log(e);
      if (e instanceof Error) {
        console.log(e.response?.data?.message);
        this.setAuthError(e.response?.data?.message ?? "Неизвестная ошибка");
      }
    }
  }

  async confirmReg(email, token) {
    try {
      const response = await AuthService.confirmReg(email, token);
      const responseData = response.data.data;

      localStorage.setItem("token", responseData.accessToken);
      this.setConfirmRequest(false);
      this.setAuth(true);
      this.setAuthError(false);
    } catch (e) {
      console.log(e);
      if (e instanceof Error) {
        console.log(e.response?.data?.message);
        this.setAuthError(e.response?.data?.message ?? "Неизвестная ошибка");
      }
    }
  }

  async logout() {
    try {
      localStorage.removeItem("email");
      localStorage.removeItem("token");
      localStorage.removeItem("userParams");
      localStorage.removeItem("userParamsValues");
      // localStorage.clear();
      this.setAuth(false);
      this.setUserParams(false);
      // this.setUser({});
    } catch (e) {
      if (e instanceof Error) {
        console.log(e.message);
      }
    }
  }

  async checkAuth() {
    this.setIsLoading(true);
    try {
      const response = await AuthService.refresh();
      if (response.status === 200) {
        localStorage.setItem("token", response.data.data.accessToken);
        if (response.data.data.user.isVerifyedAccount === false) {
          this.setConfirmRequest(true);
        } else {
          this.setAuth(true);
          if (localStorage.getItem("userParams") === "true") {
            this.setUserParams(true);
          }
        }
      }
    } catch (e) {
      if (e instanceof Error) {
        console.log(e.message);
      }
    } finally {
      this.setIsLoading(false);
    }
  }
}
