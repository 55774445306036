import React from "react";
import "./button.scss";
import { LoadingOutlined } from "@ant-design/icons";

export const ButtonComp = ({
  onButtonClick,
  buttonClassName,
  buttonStyle,
  buttonDisabled,
  buttonLoading,
  children,
  htmlType,
  isTextBtn,
  isPrimaryStyle,
  isSecondStyle,
}) => {
  const classNames = [
    "button",
    buttonClassName,
    isTextBtn
      ? "button-text"
      : isPrimaryStyle
      ? "button-primary"
      : isSecondStyle
      ? "button-second"
      : "button-filled",
    (buttonDisabled || buttonLoading) && "disabled",
  ].join(" ");

  return (
    <button
      className={classNames}
      style={buttonStyle}
      type={htmlType || "button"}
      onClick={onButtonClick}
      disabled={buttonDisabled || buttonLoading}
    >
      {buttonLoading && (
        <LoadingOutlined style={{ fontSize: 16, color: "#fff" }} spin />
      )}
      {children}
    </button>
  );
};
