import React, { createContext } from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import Store from "./store/store";
import "./assets/scss/main.sass";

const store = new Store();
const token = localStorage.getItem("token");

export const Context = createContext({
  store,
  token,
});

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Context.Provider
    value={{
      store,
      token,
    }}
  >
    <App />
  </Context.Provider>
);
