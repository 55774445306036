import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { Context } from "../../index";
import { ReactComponent as ErrorImage } from "../../assets/svg/error-image.svg";
import "./style.scss";

export const ErrorPage = () => {
  const navigate = useNavigate();
  const { store } = useContext(Context);

  const handleLogout = async () => {
    store.logout();
    if (!store.isAuth) {
      navigate("/login");
    }
  };

  return (
    <div className="error-page-container">
      <ErrorImage width="100%" />
      <span className="error-text">Произошла ошибка при получении данных</span>
      <span className="back-container" onClick={handleLogout}>
        <ArrowLeftOutlined />
        Вернуться к авторизации
      </span>
    </div>
  );
};
