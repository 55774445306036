import React from "react";
import { ArrowLeftOutlined } from "@ant-design/icons";

import { ReactComponent as ErrorImage } from "../../../assets/svg/error-image.svg";
import { ReactComponent as ErrorFit } from "../../../assets/svg/error-fit.svg";
import { ReactComponent as SadFace } from "../../../assets/svg/sad-face.svg";
import "./style.scss";
import { ButtonComp } from "../button/Button";

export const ErrorTryPage = ({
  text,
  handleAgainClick,
  handleBackClick,
  backText,
}) => {
  return (
    <div className="page-container">
      <SadFace width="100%" />
      <div className="error-text-container">
        <span className="error-header">{text ?? "Упс..."}</span>
        <span className="error-text">
          Что-то пошло не так во время примерки.<br /> Попробуйте снова или вернитесь в каталог для других
          отличных находок
        </span>
      </div>
      <span className="prev-next-container">
        <ButtonComp type="button" onButtonClick={handleBackClick} isTextBtn>
          <ArrowLeftOutlined />
          {backText ?? "Вернуться к авторизации"}
        </ButtonComp>
        <ButtonComp htmlType="button" onButtonClick={handleAgainClick}>
          Попробовать еще раз
        </ButtonComp>
      </span>
    </div>
  );
};
