import React, { useState, useContext } from "react";
import { observer } from "mobx-react-lite";
import { useNavigate } from "react-router-dom";

import { Context } from "../../index";

import { PageHeader } from "../../components/UI/PageHeader/PageHeader";
import { Input } from "../../components/UI/input/Input";
import { ButtonComp } from "../../components/UI/button/Button";
import { SocialContainer } from "../../components/social-container/Social";
import { ToggleAuthComponent } from "../../components/toggle-auth-container/ToggleAuth";

import "./styles.scss";
import "../../assets/scss/global_styles.scss";

const Register = () => {
  const navigate = useNavigate();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [repeatPassword, setRepeatPassword] = useState("");
  const [passwordMatchError, setPasswordMatchError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const { store } = useContext(Context);

  const handleNotAuth = () => {
    localStorage.setItem("token", "not-auth");
    navigate("/user-auth");
  };

  const handleSubmit = async () => {
    if (password !== repeatPassword) {
      setPasswordMatchError(true);
      return;
    }
    setPasswordMatchError(false);
    try {
      setIsLoading(true);
      await store.registration("customer", email, password);
      localStorage.setItem("email", email);
      if (store.confirmRequest) {
        navigate("/confirm-register");
      } else if (store.isAuth) {
        navigate("/user-auth");
      }
      setIsLoading(false);
    } catch (e) {
      console.error(e);
      setIsLoading(false);
    }
  };

  return (
    <div className="auth-page-container">
      <div className="auth-block-container">
        <PageHeader text="Регистрация" />
        <form className="form-container">
          <Input
            type="email"
            id="email"
            name="email"
            value={email}
            placeholder="Email"
            handleChange={(e) => setEmail(e.target.value)}
            isLabel
          />
          <Input
            type="password"
            id="password"
            name="password"
            value={password}
            placeholder="Пароль"
            handleChange={(e) => setPassword(e.target.value)}
            isLabel
            isPassword
          />
          <Input
            type="password"
            id="repeatPassword"
            name="repeatPassword"
            value={repeatPassword}
            placeholder="Повторите пароль"
            handleChange={(e) => setRepeatPassword(e.target.value)}
            isLabel
            isPassword
            error={passwordMatchError ? "Пароли не совпадают" : ""}
          />
          <span className="prev-next-container">
            <ButtonComp
              htmlType="button"
              buttonLoading={isLoading}
              onButtonClick={handleSubmit}
              buttonStyle={{ marginTop: 8 }}
            >
              Зарегистрироваться
            </ButtonComp>
            <ButtonComp type="button" onButtonClick={handleNotAuth} isTextBtn>
              Попробовать без регистрации
            </ButtonComp>
          </span>
          {store.error && (
            <span className="error-message not-absolute-error">
              {store.error}
            </span>
          )}
        </form>
        <SocialContainer />
      </div>

      <ToggleAuthComponent
        text="Уже есть аккаунт?"
        link="/login"
        linkText="Войти"
      />
    </div>
  );
};

export default observer(Register);
