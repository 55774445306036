import $api from "../http";

export default class MediaService {
  static async uploadMedia(image, tempPhoto) {
    const formData = new FormData();
    formData.append("image", image);

    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    };

    return $api.post(
      `ms/upload${tempPhoto ? "?type=photo" : ""}`,
      formData,
      config
    );
  }
}
