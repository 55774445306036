import React, { useState, useEffect } from "react";
import { Modal } from "antd";
import { PageHeader } from "../../components/UI/PageHeader/PageHeader";
import { LoadingSpinner } from "../../components/UI/LoadingComponent/LoadingComponent";
import { PageSubtitle } from "../../components/UI/PageSubtitle/PageSubtitle";
import { ProductCard } from "../../components/UI/product-card/ProductCard";
import { CategoryCard } from "../../components/UI/category-card/CategoryCard";
import { SearchInput } from "../../components/UI/input/SearchInput";
import StoreService from "../../service/StoreService";
import { buildSizes } from "../../utils/productFunctions";
import { ButtonComp } from "../../components/UI/button/Button";
import "./styles.scss";
import "../../assets/scss/global_styles.scss";
import SelectComp from "../../components/UI/select/Select";
import { categories } from "../../helpers/select-values";
import { EmptyContainer } from "../../components/UI/empty-container/EmptyContainer";

// ProductModal component
const ProductModal = ({
  productSizes,
  activeSize,
  isModalOpen,
  handleActiveSize,
  handleModalClose,
  handleFitProduct,
  selectedProduct,
}) => {
  return (
    <Modal
      title={selectedProduct.name}
      open={isModalOpen}
      onOk={handleModalClose}
      onCancel={handleModalClose}
      okText="Примерить"
      centered
      footer={null}
    >
      <div className="popup-sizes-container">
        <span
          className="image-container"
          style={{
            width: 122,
            height: 160,
          }}
        >
          <img
            src={selectedProduct.images[0]?.url}
            alt="товар"
            className="image"
          />
        </span>
        <span className="sizes-text">Выберите размер</span>
        <div className="sizes-container">
          {productSizes.map((size, index) => {
            const activeSizeId = Number(activeSize?.split("|")[1]);
            return (
              <div
                key={index}
                className={`size-container ${
                  activeSizeId === size.id ? "active-size" : ""
                }`}
                onClick={() => handleActiveSize(size)}
              >
                <span
                  className="size-title-text"
                  style={{
                    color: activeSizeId === size.id ? "#fff" : undefined,
                  }}
                >
                  {size.size}
                </span>
                <span className="size-text">{size.sizeNumber}</span>
              </div>
            );
          })}
        </div>

        <ButtonComp
          type="button"
          onButtonClick={() => handleFitProduct(selectedProduct.id, activeSize)}
          isPrimaryStyle
          buttonDisabled={!activeSize}
          buttonStyle={{ padding: 16, marginTop: 30 }}
        >
          Примерить
        </ButtonComp>
      </div>
    </Modal>
  );
};

const Catalogue = ({ storeId, productId }) => {
  const [loadingData, setLoadingData] = useState(true);
  const [storeData, setStoreData] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [categoryValue, setCategoryValue] = useState(null);
  const [cursor, setCursor] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [productSizes, setProductSizes] = useState([]);
  const [activeSize, setActiveSize] = useState();

  const fetchData = async () => {
    if (!storeId) return;

    try {
      setLoadingData(true);
      const response = await StoreService.productSearch(
        storeId,
        searchValue,
        categoryValue ?? "",
        cursor
      );
      if (response.status === 200) {
        setStoreData(response.data.data);
      }
    } catch (e) {
      console.log(e);
    } finally {
      setLoadingData(false);
    }
  };

  const onSearch = (value) => setSearchValue(value);

  const productClick = (productData) => {
    setSelectedProduct(productData);
    setProductSizes(buildSizes(productData.productType));
    setIsModalOpen(true);
  };

  const handleActiveSize = (size) => {
    setActiveSize(`${size.productTypeName}|${size.id}`);
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
    setSelectedProduct(null);
    setProductSizes([]);
    setActiveSize();
  };

  const handleFitProduct = (productId, activeSize) => {
    localStorage.setItem("productId", productId);
    localStorage.setItem("productCategoryId", activeSize);
    window.location.reload();
  };

  useEffect(() => {
    fetchData();
  }, [searchValue, categoryValue]);

  useEffect(() => {
    if (productId && productId !== "null" && storeData.length > 0) {
      const filteredStoreData = storeData.filter(
        (item) => item.id === productId
      );
      setSelectedProduct(filteredStoreData[0]);
      productClick(filteredStoreData[0]);
    }
  }, [productId, storeData]);

  if (loadingData) {
    return <LoadingSpinner page />;
  }

  return (
    <div className="page-container" style={{ gap: 24 }}>
      <div className="section-container">
        <PageHeader text="Каталог" hasExit />
        <SearchInput onSearch={onSearch} searchValue={searchValue} />
      </div>
      <div className="section-container">
        <PageSubtitle text="Категории" />
        <SelectComp
          handleChange={(value) => setCategoryValue(value)}
          data={categories}
          value={categoryValue}
          selectId="categoryId"
          placeholder="Все товары"
          allowClear={true}
        />
      </div>
      <div className="section-container">
        {storeData.length > 0 ? (
          <>
            <PageSubtitle text="Все товары" />
            <div className="cards-container">
              {storeData?.map((data) => (
                <ProductCard
                  key={data.id}
                  data={data}
                  productClick={() => productClick(data)}
                />
              ))}
            </div>
            {selectedProduct && (
              <ProductModal
                productSizes={productSizes}
                activeSize={activeSize}
                isModalOpen={isModalOpen}
                handleActiveSize={handleActiveSize}
                handleModalClose={handleModalClose}
                handleFitProduct={handleFitProduct}
                selectedProduct={selectedProduct}
              />
            )}
          </>
        ) : (
          <EmptyContainer text="Товары отсутствуют" />
        )}
      </div>
    </div>
  );
};

export default Catalogue;
