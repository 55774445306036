import $api from "../http";

export default class StoreService {
  static getStoreData(id) {
    return $api.get(`wias/store?storeId=${id}`);
  }

  static getStoreCategoryData(id, category) {
    return $api.get(`wias/store${category}?storeId=${id}`);
  }

  static productSearch(id, searchValue, category, cursor) {
    return $api.get(
      `wias/product/search?storeId=${id}&search=${searchValue}&category=${category}&cursor=${cursor}`
    );
  }
}
