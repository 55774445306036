import React, { useState, useContext } from "react";
import { observer } from "mobx-react-lite";
import { useNavigate } from "react-router-dom";

import { Context } from "../../index";

import { PageHeader } from "../../components/UI/PageHeader/PageHeader";
import { Input } from "../../components/UI/input/Input";
import { ButtonComp } from "../../components/UI/button/Button";

import "./styles.scss";
import "../../assets/scss/global_styles.scss";
import { PageSubtitle } from "../../components/UI/PageSubtitle/PageSubtitle";
import AuthService from "../../service/AuthService";

const ConfirmRegister = () => {
  const navigate = useNavigate();

  const [email, setEmail] = useState("");
  const [code, setCode] = useState("");

  const [emptyEmail, setEmptyEmail] = useState(false);

  const { store } = useContext(Context);

  const handleConfirmReg = async () => {
    try {
      await store.confirmReg(localStorage.getItem("email") ?? email, code);
      if (store.isAuth) {
        navigate("/user-auth");
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleResendCode = async () => {
    try {
      const response = await AuthService.resendCode(
        "register",
        localStorage.getItem("email") ?? email
      );
  
    } catch (error) {
      console.error(error);
      if (error.response.status === 400) {
        store.setAuthError(error.response?.data?.message ?? "Неизвестная ошибка");
        setEmptyEmail(true);
      }
    }
  };

  return (
    <div className="auth-page-container">
      <div className="auth-block-container">
        <PageHeader text="Подтверждение регистрации" />
        <PageSubtitle text="Мы отправили вам код на электронную почту, введите его ниже" />
        <form className="form-container">
          <Input
            type="text"
            id="code"
            name="code"
            value={code}
            placeholder="4-значный код"
            handleChange={(e) => setCode(e.target.value)}
            isLabel
          />
          {emptyEmail && (
            <Input
              type="email"
              id="email"
              name="email"
              value={email}
              label="Введите почту от аккаунта"
              placeholder="email"
              handleChange={(e) => setEmail(e.target.value)}
              isLabel
            />
          )}
          <span className="prev-next-container">
            <ButtonComp
              type="button"
              onButtonClick={handleResendCode}
              isTextBtn
            >
              Отправить код повторно
            </ButtonComp>
            <ButtonComp
              htmlType="button"
              onButtonClick={handleConfirmReg}
              buttonStyle={{ marginTop: 8 }}
            >
              Подтвердить
            </ButtonComp>
          </span>
          {store.error && (
            <span className="error-message not-absolute-error">
              {store.error}
            </span>
          )}
        </form>
      </div>
    </div>
  );
};

export default observer(ConfirmRegister);
