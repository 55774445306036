import { Radio } from "antd";
import "./style.scss";

export const RadioGroup = ({
  value,
  onChange,
  radioData,
  isLabel,
  label,
  radioId,
  className,
  withImages,
}) => {
  return (
    <span className="radio-group-container">
      {isLabel && <label htmlFor={radioId}>{label}</label>}
      <Radio.Group
        id={radioId}
        onChange={onChange}
        value={value}
        className={className}
      >
        {radioData.map((item, index) => (
          <Radio key={index} value={item.value}>
            {item.label}
          </Radio>
        ))}
      </Radio.Group>
    </span>
  );
};
