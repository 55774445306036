import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import "./style.scss";

import { ReactComponent as LogOut } from "../../../assets/icons/logOut.svg";
import { Context } from "../../../index";

export const Logout = () => {
  const navigate = useNavigate();
  const { store } = useContext(Context);

  const handleLogout = async () => {
    const confirmLogout = window.confirm("Вернуться к авторизации?");
    if (confirmLogout) {
      store.logout();
      if (!store.isAuth) {
        navigate("/login");
      }
    }
  };

  return <LogOut className="logout" onClick={handleLogout} />;
};
