import { Link } from "react-router-dom";
import "./style.scss";

export const ToggleAuthComponent = ({ text, link, linkText }) => {
  return (
    <div className="toggle-container">
      <span className="toggle-text">{text}</span>
      <span className="toggle-link-container">
        <Link className="toggle-link" to={link}>{linkText}</Link>
      </span>
    </div>
  );
};
