import React, { useState, useEffect, useMemo } from "react";
import { useLocation } from "react-router-dom";
import Catalogue from "../catalogue/index";
import { ErrorPage } from "../error-page/ErrorPage";
import Fitting from "../fitting-result";

const Wis = () => {
  const location = useLocation();

  // Use useMemo to create the searchParams object
  const searchParams = useMemo(
    () => new URLSearchParams(location.search),
    [location.search]
  );

  // Initialize state for storeId and productId
  const [storeId, setStoreId] = useState(
    searchParams.get("storeId") ?? localStorage.getItem("storeId")
  );
  const [productId, setProductId] = useState(
    searchParams.get("productId") ?? localStorage.getItem("productId")
  );

  const [productCategoryId, setProductCategoryId] = useState(
    localStorage.getItem("productCategoryId")
  );

  // Update state when searchParams change
  useEffect(() => {
    const handleStorageChange = (e) => {
      if (e.key === "storeId" || e.key === "productId") {
        const newStoreId =
          searchParams.get("storeId") || localStorage.getItem("storeId");
        const newProductId =
          searchParams.get("productId") || localStorage.getItem("productId");
        const newProductCategoryId =
          searchParams.get("productCategoryId") ||
          localStorage.getItem("productCategoryId");
        setStoreId(newStoreId);
        setProductId(newProductId);
        setProductCategoryId(newProductCategoryId);
      }
    };

    window.addEventListener("storage", handleStorageChange);

    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, [searchParams, setStoreId, setProductId]);

  if (
    productCategoryId &&
    productCategoryId !== "null" &&
    productId &&
    productId !== "null" &&
    storeId &&
    storeId !== "null"
  ) {
    return (
      <Fitting
        storeId={storeId}
        productId={productId}
        productCategoryId={productCategoryId}
      />
    );
  } else if (
    (storeId && storeId !== "null") ||
    (storeId && storeId !== "null" && productId && productId !== "null")
  ) {
    return <Catalogue storeId={storeId} productId={productId} />;
  } else {
    return <ErrorPage />;
  }
};

export default Wis;
