import { ssoUserUrl } from "../helpers/config";
import $api from "../http";

export default class AuthService {
  static async login(email, password) {
    return $api.post(`${ssoUserUrl}login`, { email, password });
  }

  static async registration(type, email, password) {
    return $api.post(`${ssoUserUrl}register`, { type, email, password });
  }

  static async confirmReg(email, token) {
    return $api.post(`${ssoUserUrl}confirm_register`, { email, token });
  }

  static async resendCode(action, email) {
    return $api.post(`${ssoUserUrl}reset_code`, { action, email });
  }

  static async getGoogleLink() {
    return $api.get(`${ssoUserUrl}google`);
  }

  static async getYandexLink() {
    return $api.get(`${ssoUserUrl}yandex`);
  }

  static async getVkLink() {
    return $api.get(`${ssoUserUrl}vk`);
  }

  static async refresh() {
    return $api.post(`${ssoUserUrl}refresh`);
  }

  static resetCustomerReg() {
    return $api.post(`${ssoUserUrl}reset`);
  }
}
