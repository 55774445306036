import React, { useState, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { message } from "antd";
import { Context } from "../../index";

import {
  genderData,
  bodyTypes,
  breastSizeNumbersData,
  breastSizeLettersData,
} from "../../helpers/select-values";
import { figuresData } from "../../helpers/radio-group-values";

import MediaService from "../../service/MediaService";
import UserService from "../../service/UserService";

import { ButtonComp } from "../../components/UI/button/Button";
import { PageHeader } from "../../components/UI/PageHeader/PageHeader";
import { Input } from "../../components/UI/input/Input";
// import DateInput from "../../components/UI/input/DateInput";
import Select from "../../components/UI/select/Select";
import TwoSelects from "../../components/UI/select/TwoSelects";
import { RadioGroup } from "../../components/UI/radio-group/RadioGroup";
import { PageSubtitle } from "../../components/UI/PageSubtitle/PageSubtitle";
import { LinkComp } from "../../components/UI/link/Link";
import { PhotoContainer } from "../../components/photo-container/PhotoContainer";

import { ReactComponent as Lock } from "../../assets/icons/lock.svg";
import "../../assets/scss/global_styles.scss";
import "./styles.scss";
import { Alert } from "antd";

const UserAuth = () => {
  const navigate = useNavigate();
  const [step, setStep] = useState(1);

  const { store } = useContext(Context);

  const [messageApi, contextHolder] = message.useMessage();

  const handleNextStep = () => {
    if (isStepFieldsFilled(step)) {
      setStep(step + 1);
    }
  };

  const handlePreviousStep = () => {
    setStep(step - 1);
  };

  const [isLoading, setIsLoading] = useState(false);

  const [gender, setGender] = useState(null);
  const [height, setHeight] = useState("");
  const [weight, setWeight] = useState("");
  const [breastSizeNumber, setBreastSizeNumber] = useState(null);
  const [breastSizeLetter, setBreastSizeLetter] = useState(null);
  // const [date, setDate] = useState("946684810000");

  const [bodyType, setBodyType] = useState(null);
  const [figureType, setFigureType] = useState("");

  const [frontImage, setFrontImage] = useState(null);
  const [frontImageLink, setFrontImageLink] = useState(null);
  const [loadingFront, setLoadingFront] = useState(false);

  const [sideImage, setSideImage] = useState(null);
  const [sideImageLink, setSideImageLink] = useState(null);
  const [loadingSide, setLoadingSide] = useState(false);

  const initializeStateFromLocalStorage = () => {
    const userParamsValues = JSON.parse(
      localStorage.getItem("userParamsValues")
    );

    if (userParamsValues) {
      const {
        gender,
        height,
        weight,
        breastSizeNumber,
        breastSizeLetter,
        bodyType,
        figureType,
      } = userParamsValues;

      setGender(gender || null);
      setHeight(height || "");
      setWeight(weight || "");
      setBreastSizeNumber(breastSizeNumber || null);
      setBreastSizeLetter(breastSizeLetter || null);
      setBodyType(bodyType || null);
      setFigureType(figureType || "");
    }
  };

  const handleUploadImage = async (event, isFront) => {
    const selectedImage = event.target.files[0];
    if (!selectedImage) {
      return;
    }

    try {
      if (isFront) {
        setLoadingFront(true);
      } else {
        setLoadingSide(true);
      }
      const response = await MediaService.uploadMedia(selectedImage, isFront);
      if (isFront) {
        setFrontImage(selectedImage);
        setFrontImageLink(response.data);
      } else {
        setSideImage(selectedImage);
        setSideImageLink(response.data);
      }
    } catch (error) {
      console.error("Ошибка при отправке сообщения:", error);
    } finally {
      setLoadingFront(false);
      setLoadingSide(false);
    }
  };

  const handleRemoveFrontImage = (e) => {
    e.preventDefault();
    setFrontImage(null);
    setFrontImageLink(null);
  };

  const handleRemoveSideImage = (e) => {
    e.preventDefault();
    setSideImage(null);
    setSideImageLink(null);
  };

  const [requiredFieldsStep1, setRequiredFieldsStep1] = useState([
    height,
    weight,
    gender,
  ]);
  const requiredFieldsStep2 = [bodyType, figureType];
  const requiredFieldsStep3 = [frontImageLink, sideImageLink];

  useEffect(() => {
    initializeStateFromLocalStorage();
  }, []);

  useEffect(() => {
    if (gender === "male") {
      setRequiredFieldsStep1([height, weight, gender]);
      setBreastSizeNumber("");
      setBreastSizeLetter("");
    } else {
      setRequiredFieldsStep1([
        height,
        weight,
        gender,
        breastSizeNumber,
        breastSizeLetter,
      ]);
    }
  }, [height, weight, gender, breastSizeNumber, breastSizeLetter]);

  const isStepFieldsFilled = (step) => {
    switch (step) {
      case 1:
        return requiredFieldsStep1.every((field) => Boolean(field));
      case 2:
        return requiredFieldsStep2.every((field) => Boolean(field));
      // case 3:
      //   return requiredFieldsStep3.every((field) => Boolean(field));
      default:
        return true;
    }
  };

  const saveParamsInLS = () => {
    if (height < 100 || height > 250 || weight < 40 || weight > 200) {
      messageApi.open({
        type: "error",
        content: `Проверьте корректность параметров: ${
          height < 100 || height > 250 ? "Рост" : ""
        }${
          (weight < 40 || weight > 200) && (height < 100 || height > 250)
            ? ", "
            : ""
        } ${weight < 40 || weight > 200 ? "Вес" : ""}`,
      });
    } else {
      const userParamsValues = {
        gender,
        height,
        weight,
        breastSizeNumber,
        breastSizeLetter,
        bodyType,
        figureType,
      };

      localStorage.setItem(
        "userParamsValues",
        JSON.stringify(userParamsValues)
      );
      localStorage.setItem("userParams", true);
      store.setUserParams(true);
    }
  };

  const handleSubmit = async () => {
    if (isStepFieldsFilled(step)) {
      try {
        setIsLoading(true);

        // const timeStamp = Math.floor(new Date(date).getTime() / 1000);
        const breastSize =
          breastSizeLetter && breastSizeNumber
            ? breastSizeLetter.concat(breastSizeNumber)
            : null;
        await UserService.userAuth(
          null,
          "WebUser",
          gender,
          bodyType,
          Number(height),
          Number(weight),
          Number(figureType),
          breastSize,
          frontImageLink,
          sideImageLink
        );
        saveParamsInLS();
        setIsLoading(false);
      } catch (e) {
        setIsLoading(false);
        console.error(e);
      }
    } else {
      alert('Заполните недостающие поля')
    }
  };

  const handleLogout = async () => {
    store.logout();
    if (!store.isAuth) {
      navigate("/login");
    }
  };

  return (
    <div className="page-container">
      {contextHolder}
      <PageHeader text="Онлайн-примерка Swize" />

      {step === 1 && (
        <div className="auth-block-container" style={{ gap: 24 }}>
          <PageSubtitle text="1/3 Общие данные" />
          <form onSubmit={handleSubmit} className="form-container">
            <Input
              type="number"
              id="height"
              name="height"
              value={height}
              placeholder="Рост (см)"
              handleChange={(e) => setHeight(e.target.value)}
            />
            <Input
              type="number"
              id="weight"
              name="weight"
              value={weight}
              placeholder="Вес (кг)"
              handleChange={(e) => setWeight(e.target.value)}
            />

            <Select
              handleChange={(value) => setGender(value)}
              data={genderData}
              value={gender}
              selectId="gender"
              placeholder="Пол"
            />
            {gender === "female" && (
              <TwoSelects
                firstData={breastSizeNumbersData}
                secondData={breastSizeLettersData}
                firstValue={breastSizeNumber}
                secondValue={breastSizeLetter}
                firstPlaceholder="Обхват под грудью"
                secondPlaceholder="Размер чашечки"
                handleChangeFirst={(value) => setBreastSizeNumber(value)}
                handleChangeSecond={(value) => setBreastSizeLetter(value)}
                isLabel
                selectId="breast"
                label="Размер груди"
              />
            )}
            <span className="prev-next-container">
              <ButtonComp type="button" onButtonClick={handleLogout} isTextBtn>
                Вернуться к авторизации
              </ButtonComp>
              <ButtonComp
                type="button"
                onButtonClick={handleNextStep}
                buttonDisabled={!isStepFieldsFilled(1)}
              >
                Продолжить
              </ButtonComp>
            </span>
          </form>
        </div>
      )}

      {step === 2 && (
        <div className="auth-block-container" style={{ gap: 24 }}>
          <PageSubtitle text="2/3 Параметры фигуры" />
          <form onSubmit={handleSubmit} className="form-container">
            <Select
              handleChange={(value) => setBodyType(value)}
              data={bodyTypes}
              value={bodyType}
              selectId="bodyType"
              placeholder="Телосложение"
            />
            <RadioGroup
              value={figureType}
              onChange={(e) => setFigureType(e.target.value)}
              radioData={figuresData}
              isLabel
              radioId="figure"
              label="Тип фигуры"
              className="figure-className"
              withImages
            />
            <span className="prev-next-container">
              <ButtonComp
                type="button"
                onButtonClick={handlePreviousStep}
                isTextBtn
              >
                Назад
              </ButtonComp>
              <ButtonComp
                type="button"
                onButtonClick={handleNextStep}
                buttonDisabled={!isStepFieldsFilled(2)}
              >
                Продолжить
              </ButtonComp>
            </span>
          </form>
        </div>
      )}

      {step === 3 && (
        <div className="auth-block-container" style={{ gap: 24 }}>
          <PageSubtitle text="3/3 Фото фигуры" />

          <form onSubmit={handleSubmit} className="form-container">
            {store.isAuth ? (
              <span className="form-photo-true-container">
                <span className="description-text">
                  Для более точного распознания параметров фигуры сделайте два
                  фото в полный рост в нижнем белье, либо в обтягивающей одежде.
                  Допускается фото отражения в зеркале. Эти фото никто не
                  увидит, кроме вас
                </span>
                <span className="photos-container">
                  <PhotoContainer
                    image={frontImage}
                    loading={loadingFront}
                    isFront={true}
                    handleRemoveImage={handleRemoveFrontImage}
                    handleUploadImage={handleUploadImage}
                  />
                  <PhotoContainer
                    image={sideImage}
                    loading={loadingSide}
                    isFront={false}
                    handleRemoveImage={handleRemoveSideImage}
                    handleUploadImage={handleUploadImage}
                  />
                </span>
              </span>
            ) : (
              <span className="form-photo-false-container">
                <span className="lock-text-container">
                  <Lock />
                  Доступно для авторизованных пользователей
                </span>
                <span className="description-text">
                  Эта опция позволяет сделать результат более точным с помощью
                  технологии компьютерного зрения. Ваши фото никто не увидит,
                  регистрация нужна для корректного сохранения результата
                  обработки
                </span>
                <LinkComp link="/login" textLink="Авторизоваться" />
              </span>
            )}

            <span className="prev-next-container">
              <ButtonComp
                type="button"
                onButtonClick={handlePreviousStep}
                isTextBtn
              >
                Назад
              </ButtonComp>
              {store.isAuth ? (
                <ButtonComp
                  type="button"
                  buttonLoading={isLoading}
                  onButtonClick={handleSubmit}
                  // buttonDisabled={!isStepFieldsFilled(3)}
                >
                  {requiredFieldsStep3.every((field) => Boolean(field)) ? "Сохранить" : "Пропустить"}
                </ButtonComp>
              ) : (
                <ButtonComp type="text" onButtonClick={saveParamsInLS}>
                  Пропустить
                </ButtonComp>
              )}
            </span>
          </form>
        </div>
      )}
    </div>
  );
};

export default observer(UserAuth);
