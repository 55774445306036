export function buildSizes(productType) {
  const params =
    productType.name === "LongSleeve"
      ? productType.longSleeveParams
      : productType[productType.name.toLowerCase() + "Params"];

  const sizes = params.map((param) => {
    const productTypeName = productType.name;
    const size = param.size;
    const id = param.id;
    let sizeNumber = "";

    switch (size) {
      case "XXS":
        sizeNumber = "38-40";
        break;
      case "XS":
        sizeNumber = "40-42";
        break;
      case "S":
        sizeNumber = "42-44";
        break;
      case "M":
        sizeNumber = "44-46";
        break;
      case "L":
        sizeNumber = "46-48";
        break;
      case "XL":
        sizeNumber = "48-50";
        break;
      case "XXL":
        sizeNumber = "50-52";
        break;
      case "XXXL":
        sizeNumber = "52-54";
        break;
      default:
        sizeNumber = "без размера";
        break;
    }

    return { productTypeName, size, id, sizeNumber };
  });

  return sizes;
}

export function getCategoryName(productType) {
  switch (productType) {
    case "TShirt":
      return "Футболки";
    case "Shirt":
      return "Рубашки";
    case "LongSleeve":
      return "Кофты";
    case "Hoodie":
      return "Худи";
    case "Trousers":
      return "Брюки";
    case "Shorts":
      return "Шорты";
    case "Skirt":
      return "Юбки";
    default:
      return "";
  }
}

export function getProductTypeName(category) {
  const categoryToProductType = {
    TShirt: "tshirtId",
    Shirt: "shirtId",
    LongSleeve: "longsleeveId",
    Hoodie: "hoodieId",
    Trousers: "trousersId",
    Shorts: "shortsId",
    Skirt: "skirtId",
  };

  return categoryToProductType[category] || "";
}
