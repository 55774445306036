import React, { useEffect, useContext } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { Context } from "../../index";
import StoreService from "../../service/AuthService";

import { ReactComponent as Google } from "../../assets/svg/social/google.svg";
import { ReactComponent as Yandex } from "../../assets/svg/social/yandex.svg";
import { ReactComponent as Vk } from "../../assets/svg/social/vk.svg";
import "./style.scss";

export const SocialContainer = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const { store } = useContext(Context);

  const handleGoogleAuth = async () => {
    try {
      const response = await StoreService.getGoogleLink();
      window.location.href = response.data.data.url;
    } catch (e) {
      console.error(e);
    }
  };

  const handleYandexAuth = async () => {
    try {
      const response = await StoreService.getYandexLink();
      console.log(response);
    } catch (e) {
      console.error(e);
    }
  };

  const handleVkAuth = async () => {
    try {
      const response = await StoreService.getVkLink();
      window.location.href = response.data.data.url;
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const token = queryParams.get("token");
    const isCompleteReg = queryParams.get("isCompleteCustomerReg");
    const userData = {
      isVerifyedAccount: true,
      isCompleteCustomerReg: isCompleteReg === "true",
    };

    if (token) {
      try {
        store.processUserData(userData, token);
        console.log(store);
        if (store.isAuth && store.userParams) {
          navigate("/wis");
        } else if (store.isAuth) {
          navigate("/user-auth");
        }
      } catch (e) {
        console.error(e);
      }
    }
  }, [location]);

  return (
    <div className="social-container">
      <span className="social-title">Авторизоваться через</span>
      <div className="social-buttons-container">
        <Google className="social-button" onClick={handleGoogleAuth} />
        <Yandex className="social-button" onClick={handleYandexAuth} />
        <Vk className="social-button" onClick={handleVkAuth} />
      </div>
    </div>
  );
};
