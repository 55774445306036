export const baseUrl = "https://swize.geryon.space/";
export const ssoUrl = baseUrl + "sso/";
export const ssoSwizeUrl = ssoUrl + "swize/";
export const ssoUserUrl = ssoUrl + "user/";
//SSO URLS
export const login = ssoUserUrl + "login";
export const register = ssoUserUrl + "register";
export const confirmRegister = ssoUserUrl + "confirm_register";
export const resetCode = ssoUserUrl + "reset_code";

export const userCheck = ssoUserUrl + "check";
export const changePass = ssoUserUrl + "change";
export const forgotPass = ssoUserUrl + "forgot";
export const confirmPass = ssoUserUrl + "confirm_password";

export const refreshToken = ssoUserUrl + "refresh";
export const deviceToken = ssoUserUrl + "device";

export const googleAuth = ssoUserUrl + "google";
export const yandexAuth = ssoUserUrl + "yandex";
export const vkAuth = ssoUserUrl + "vk";

export const loadImage = baseUrl + "ms/upload";
