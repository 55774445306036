import { useEffect, useContext } from "react";
import Router from "./router/Router";
import { observer } from "mobx-react-lite";
import { Context } from "./index";

import "./app.scss";
import { LoadingSpinner } from "./components/UI/LoadingComponent/LoadingComponent";

function App() {
  const { store } = useContext(Context);

  useEffect(() => {
    if (localStorage.getItem("token") === "not-auth") {
      store.setUserParams(true);
    } else if (localStorage.getItem("token")) {
      store.checkAuth();
    }
  }, []);

  if (store.isLoading) {
    return <LoadingSpinner page />;
  }
  return (
    <div className="main-container">
      <Router />
    </div>
  );
}

export default observer(App);
