export const genderData = [
  { value: "male", label: "Мужской" },
  { value: "female", label: "Женский" },
];

export const bodyTypes = [
  { value: "somethin", label: "Очень худощавое" },
  { value: "thin", label: "Худощавое" },
  { value: "mean", label: "Среднее телосложение" },
  { value: "fat", label: "Полное" },
  { value: "somefat", label: "Очень полное" },
];

export const categories = [
  { value: "Shirt", label: "Рубашки" },
  { value: "TShirt", label: "Футболки" },
  { value: "LongSleeve", label: "Толстовки" },
  { value: "Hoodie", label: "Худи" },
  { value: "Shorts", label: "Шорты" },
  { value: "Trousers", label: "Брюки" },
  { value: "Skirt", label: "Юбки" },
];

// export const figureTypes = [
//   { value: "1", label: "Прямоугольник" },
//   { value: "2", label: "Круг" },
//   { value: "3", label: "Груша" },
//   { value: "4", label: "Треугольник" },
//   { value: "5", label: "Песочные часы" },
// ];

export const breastSizeNumbersData = [
  { value: "70", label: "70" },
  { value: "75", label: "75" },
  { value: "80", label: "80" },
  { value: "85", label: "85" },
  { value: "90", label: "90" },
  { value: "95", label: "95" },
];

export const breastSizeLettersData = [
  { value: "A", label: "A" },
  { value: "B", label: "B" },
  { value: "C", label: "C" },
  { value: "D", label: "D" },
  { value: "E", label: "E" },
  { value: "F", label: "F" },
];
