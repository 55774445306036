import React from "react";
import "./style.scss";
import { Link } from "react-router-dom";

export const LinkComp = ({ link, textLink }) => {
  return (
    <Link className="link" to={link}>
      {textLink}
    </Link>
  );
};
