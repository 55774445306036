import { useNavigate } from "react-router-dom";

import { PageHeader } from "../../components/UI/PageHeader/PageHeader";
import { ButtonComp } from "../../components/UI/button/Button";

import { ReactComponent as App } from "../../assets/svg/stores/appstore.svg";
import { ReactComponent as Play } from "../../assets/svg/stores/playmarket.svg";

import { ReactComponent as Logo } from "../../assets/svg/auth/logo.svg";
import { ReactComponent as Example } from "../../assets/svg/auth/example.svg";
import { ReactComponent as Edit } from "../../assets/svg/auth/edit-icon.svg";
import { ReactComponent as Camera } from "../../assets/svg/auth/camera-icon.svg";
import { ReactComponent as Fire } from "../../assets/svg/auth/fire-icon.svg";

import "./styles.scss";
import "../../assets/scss/global_styles.scss";
import { useState } from "react";

const Welcome = () => {
  const navigate = useNavigate();
  const [step, setStep] = useState(1);

  const handleNextStep = () => {
    setStep(step + 1);
    console.log(step);
  };

  const handleNotAuth = () => {
    localStorage.setItem("token", "not-auth");
    navigate("/user-auth");
  };

  return (
    <div className="page-container">
      {step === 1 && (
        <div className="welcome-first-container">
          <span style={{ marginBottom: -8 }}>
            <Logo />
          </span>
          <span className="welcome-text" style={{ textAlign: "center" }}>
            Примерьте товар, не выходя
            <br /> из дома с помощью бесплатной
            <br /> функции виртуальной примерки
          </span>
          <Example width="100%" height="100%" />
          <ButtonComp
            // buttonStyle={{ marginBottom: 10 }}
            type="text"
            onButtonClick={handleNextStep}
          >
            Попробовать
          </ButtonComp>
        </div>
      )}
      {step === 2 && (
        <>
          <PageHeader text="Онлайн-примерка Swize" />
          <div className="welcome-block-container">
            <div className="section-container">
              <span className="section-title">Как это работает?</span>
              <div className="work-point-container">
                <Edit />
                <span>Введите свои параметры</span>
              </div>
              <div className="work-point-container">
                <Camera />
                <span>Прикрепите фото для точности*</span>
              </div>
              <div className="work-point-container">
                <Fire />
                <span>Оцените результат</span>
              </div>
              <span className="work-clue">
                *Это не обязательный, но важный шаг для получения точного
                результата. Функция доступна авторизованным пользователям
              </span>
            </div>
            <div className="section-container">
              <span className="section-title">Удобнее в приложении</span>
              <div className="stores-container">
                <App width="100%" height="100%" />
                <Play width="100%" height="100%" />
              </div>
            </div>
            <span className="prev-next-container" style={{ margin: "16px 0" }}>
              <ButtonComp
                type="button"
                onButtonClick={() => navigate("/login")}
                isTextBtn
              >
                Войти / Зарегистрироваться
              </ButtonComp>
              <ButtonComp type="button" onButtonClick={handleNotAuth}>
                Попробовать без авторизации
              </ButtonComp>
            </span>
          </div>
        </>
      )}
    </div>
  );
};

export default Welcome;
