import { Select } from "antd";
import "./style.scss";

const SelectComp = ({
  data,
  placeholder,
  value,
  handleChange,
  isLabel,
  selectId,
  label,
  allowClear,
}) => {
  return (
    <span className="select-container">
      {isLabel && <label htmlFor={selectId}>{label}</label>}
      <Select
        placeholder={placeholder}
        value={value}
        className="select"
        onChange={handleChange}
        options={data}
        allowClear={allowClear}
      />
    </span>
  );
};

export default SelectComp;
