import React from "react";
import { Carousel } from "antd";
import "./style.scss";
import noFit from "../../../assets/images/not-fit.png";

export const SliderComp = ({ elements }) => {
  const onChange = (currentSlide) => {
    console.log(currentSlide);
  };

  return (
    <Carousel afterChange={onChange} swipeToSlide draggable>
      {elements.map((imageUrl, index) => (
        <div key={index} className={`slide`}>
          <img
            src={imageUrl ?? noFit}
            alt={`Слайд №${index + 1}`}
            className="slide-image"
          />
        </div>
      ))}
    </Carousel>
  );
};
