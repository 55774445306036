import { Input } from "antd";

import "./style.scss";
import "../../../assets/scss/global_styles.scss";

const { Search } = Input;

export const SearchInput = (props) => {
  return (
    <span className="search_input_container">
      <Search
        placeholder="Поиск"
        defaultValue={props.searchValue}
        // onChange={props.onChange}
        allowClear
        onSearch={props.onSearch}
        className="search-input"
        size="large"
      />
    </span>
  );
};
