import { useContext } from "react";

import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { observer } from "mobx-react-lite";

import { Context } from "../index";

import { PrivateRoute } from "./PrivateRoute";
import { PublicRoute } from "./PublicRoute";
import RouteHandler from "./RouteHandler";

import Login from "../pages/auth/Login";
import Register from "../pages/auth/Register";
import UserAuth from "../pages/auth/UserAuth";

import Welcome from "../pages/welcome";
import Fitting from "../pages/fitting-result";
import ConfirmRegister from "../pages/auth/ConfirmRegister";
import Wis from "../pages/wis";

const Router = () => {
  const { store, token } = useContext(Context);

  return (
    <BrowserRouter>
      <RouteHandler />
      <Routes>
        {/* Nested Route component with a PrivateRoute component as its child */}
        <Route element={<PrivateRoute user={store.userParams} token={token} />}>
          <Route path="/fitting" element={<Fitting />} />
          <Route path="/wis" element={<Wis />} />
        </Route>
        {/* Nested Route component with a PublicRoute component as its child */}
        <Route element={<PublicRoute user={store.userParams} />}>
          <Route path="/welcome" element={<Welcome />} />
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route path="/confirm-register" element={<ConfirmRegister />} />
          <Route path="/user-auth" element={<UserAuth />} />
        </Route>
        <Route
          path="*"
          element={
            <Navigate
              to={
                store.userParams === true
                  ? "/wis"
                  : token && store.confirmRequest
                  ? "/confirm-register"
                  : token
                  ? "/user-auth"
                  : "/welcome"
              }
              replace
            />
          }
        />
      </Routes>
    </BrowserRouter>
  );
};

export default observer(Router);
