import { buildSizes } from "../../../utils/productFunctions";
import "./style.scss";

export const ProductCard = ({ data, productClick }) => {
  const sizes = buildSizes(data.productType);

  const sizeString = sizes.map((size) => size.size).join(", ");

  return (
    <div className="product-card-container">
      <span className="image-container">
        <img src={data.images[0]?.url} alt="товар" className="image" />
      </span>
      <div className="info-container">
        <span className="product-info-container">
          <span className="card-name">{data.name}</span>
          <span className="card-subname">{sizeString}</span>
        </span>
        <button className="card-button" onClick={productClick}>
          Примерить
        </button>
      </div>
    </div>
  );
};
