// RouteHandler.js
import { useEffect } from "react";
import { useLocation } from "react-router-dom";

const RouteHandler = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const storeId = searchParams.get("storeId");
  const productId = searchParams.get("productId");

  useEffect(() => {
    if (storeId !== null) {
      localStorage.setItem("storeId", storeId);
    }
    if (productId !== null) {
      localStorage.setItem("productId", productId);
    }
  }, [storeId, productId]);

  return null;
};

export default RouteHandler;
