import { LoadingOutlined } from "@ant-design/icons";
import { ReactComponent as Camera } from "../../assets/icons/camera.svg";
import { ReactComponent as Cross } from "../../assets/icons/cross.svg";
import './style.scss'

export const PhotoContainer = ({
  image,
  loading,
  isFront,
  handleRemoveImage,
  handleUploadImage,
}) => {
  return (
    <span className="photo-container">
      <label
        htmlFor={`${isFront ? "front" : "side"}ImageInput`}
        className="photo-button"
      >
        {image ? (
          <>
            <img
              src={URL.createObjectURL(image)}
              alt={isFront ? "Uploaded Front" : "Uploaded Side"}
              className="image"
            />
            <span className="photo-remove" onClick={handleRemoveImage}>
              <Cross />
            </span>
          </>
        ) : loading ? (
          <LoadingOutlined style={{ fontSize: 24, color: "#5F51F4" }} spin />
        ) : (
          <Camera width={24} height={24} />
        )}
        <input
          type="file"
          id={`${isFront ? "front" : "side"}ImageInput`}
          accept="image/*"
          onChange={(event) => handleUploadImage(event, isFront)}
          style={{ display: "none" }}
        />
      </label>
      <span className="photo-text-container">
        <span className="top-text">Фото {isFront ? "спереди" : "сбоку"}</span>
        <span className="bottom-text">Обязательно</span>
      </span>
    </span>
  );
};
