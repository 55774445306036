import $api from "../http";

export default class UserService {
  static async userAuth(
    yearOfBirth,
    name,
    sex,
    bodyType,
    height,
    weight,
    figureTypeId,
    breastSize,
    frontPhoto,
    sidePhoto
  ) {
    return $api.post("wias/customer/register", {
      yearOfBirth,
      name,
      sex,
      bodyType,
      height,
      weight,
      figureTypeId,
      breastSize,
      frontPhoto,
      sidePhoto,
    });
  }

  static getFigureTypes() {
    return $api.get("wias/figureType");
  }

  static async fitOnAuthCustomer(productCategoryName, productCategoryId) {
    return $api.post("wias/customer/try", {
      [productCategoryName]: productCategoryId,
    });
  }

  static async fitOnNotAuthCustomer(
    gender,
    height,
    weight,
    figureTypeId,
    productCategoryName,
    productCategoryId
  ) {
    return $api.post("wias/customer/non_auth_try", {
      gender,
      height,
      weight,
      figureTypeId,
      [productCategoryName]: productCategoryId,
    });
  }
}
