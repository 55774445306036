import React, { useState, useContext, useEffect } from "react";
import { Modal } from "antd";
import { useNavigate } from "react-router-dom";
import { saveAs } from "file-saver";

import { Context } from "../../index";

import { PageHeader } from "../../components/UI/PageHeader/PageHeader";
import { ButtonComp } from "../../components/UI/button/Button";
import { SliderComp } from "../../components/UI/sliderComp/SliderComp";
import { LinkComp } from "../../components/UI/link/Link";
import { LoadingSpinner } from "../../components/UI/LoadingComponent/LoadingComponent";

import UserService from "../../service/UserService";
import AuthService from "../../service/AuthService";

import { getProductTypeName } from "../../utils/productFunctions";

import { ReactComponent as Check } from "../../assets/icons/slider/check.svg";
import { ReactComponent as Uncheck } from "../../assets/icons/slider/uncheck.svg";
import { ReactComponent as Ruler } from "../../assets/icons/ruler.svg";
import { ReactComponent as App } from "../../assets/svg/stores/appstore.svg";
import { ReactComponent as Play } from "../../assets/svg/stores/playmarket.svg";

import "./styles.scss";
import "../../assets/scss/global_styles.scss";
import { ErrorTryPage } from "../../components/UI/error-try-page/ErrorTryPage";

const Fitting = ({ productId, storeId }) => {
  const navigate = useNavigate();
  const { store } = useContext(Context);

  const [loadingData, setLoadingData] = useState(false);
  const [fittingData, setFittingData] = useState([]);

  const [isError, setIsError] = useState(false);

  const handleToProducts = async () => {
    localStorage.removeItem("productId");
    localStorage.removeItem("productCategoryId");
    window.location.reload();
  };

  const [selectedImages, setSelectedImages] = useState([]);

  const handleImageClick = (imageUrl) => {
    setSelectedImages((prevSelectedImages) =>
      prevSelectedImages.includes(imageUrl)
        ? prevSelectedImages.filter((img) => img !== imageUrl)
        : [...prevSelectedImages, imageUrl]
    );
  };

  const saveImages = () => {
    selectedImages.forEach((imageUrl, index) => {
      const xhr = new XMLHttpRequest();
      xhr.open("GET", imageUrl, true);
      xhr.responseType = "blob";

      xhr.onload = () => {
        if (xhr.status === 200) {
          const blob = new Blob([xhr.response], { type: "image/jpeg" });
          saveAs(blob, `image_${index + 1}.jpg`);
        }
      };

      xhr.send();
    });
  };

  const handleBackToParams = async () => {
    const response = await AuthService.resetCustomerReg();
    if (response.status === 200) {
      localStorage.setItem("userParams", false);
      await store.setUserParams(false);
      navigate("/user-auth");
    }
  };

  //modal
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [okClicked, setOkClicked] = useState(false);

  const handleOk = () => {
    if (selectedImages.length > 0) {
      saveImages();
    }
    setOkClicked(true);
  };

  const handleClose = () => {
    setIsModalOpen(false);
    setOkClicked(false);
    setSelectedImages([]);
  };

  const showModal = () => {
    setIsModalOpen(true);
  };

  const dressAuthCustomer = async (productCategoryName, productCategoryId) => {
    if (!storeId) return;

    try {
      setLoadingData(true);
      if (
        localStorage.getItem("token") !== null &&
        localStorage.getItem("token") !== undefined
      ) {
        const response = await UserService.fitOnAuthCustomer(
          productCategoryName,
          productCategoryId
        );
        if (response.status === 200) {
          setFittingData(response.data.images);
        } else {
          setIsError(true);
        }
      }
      setLoadingData(false);
    } catch (e) {
      setLoadingData(false);
      setIsError(true);
      console.log(e);
    }
  };

  const dressNotAuthCustomer = async (
    gender,
    height,
    weight,
    figureTypeId,
    productCategoryName,
    productCategoryId
  ) => {
    if (!storeId) return;

    try {
      setLoadingData(true);
      if (
        localStorage.getItem("token") !== null &&
        localStorage.getItem("token") !== undefined
      ) {
        const response = await UserService.fitOnNotAuthCustomer(
          gender,
          height,
          weight,
          figureTypeId,
          productCategoryName,
          productCategoryId
        );
        if (response.status === 200) {
          setFittingData(response.data.images);
        } else {
          setIsError(true);
        }
      }
      setLoadingData(false);
    } catch (e) {
      setLoadingData(false);
      setIsError(true);
      console.log(e);
    }
  };

  const fittingUser = () => {
    const userParamsValues = JSON.parse(
      localStorage.getItem("userParamsValues")
    );
    const productCategory = localStorage.getItem("productCategoryId");

    const needUserParams =
      localStorage.getItem("token") === "not-auth" && userParamsValues;

    if (needUserParams && productCategory) {
      const {
        gender,
        height,
        weight,
        breastSizeNumber,
        breastSizeLetter,
        bodyType,
        figureType,
      } = userParamsValues;
      dressNotAuthCustomer(
        gender,
        height,
        weight,
        figureType,
        getProductTypeName(productCategory.split("|")[0]),
        Number(productCategory.split("|")[1])
      );
    } else if (productCategory) {
      dressAuthCustomer(
        getProductTypeName(productCategory.split("|")[0]),
        Number(productCategory.split("|")[1])
      );
    }
  };

  useEffect(() => {
    fittingUser();
  }, []);

  if (loadingData) {
    return <LoadingSpinner page />;
  }
  if (isError) {
    return <ErrorTryPage handleAgainClick={fittingUser} handleBackClick={handleToProducts} backText='Вернуться в каталог' />;
  }
  return (
    <div className="page-container" style={{ gap: 14 }}>
      <PageHeader text="Онлайн-примерка Swize" hasExit />
      <div className="fitting-block-container">
        <span className="fitting-title">
          Рекомендуемые размеры для вас: <b>M, L </b>
        </span>
        <span className="params-back-container" onClick={handleBackToParams}>
          <Ruler />
          <LinkComp link="" textLink="Изменить параметры" />
        </span>

        <span className="slider-container">
          <SliderComp elements={fittingData} />
        </span>
        <span className="complete-fit-container">
          <ButtonComp
            type="button"
            onButtonClick={handleToProducts}
            isTextBtn
            buttonStyle={{ padding: 16 }}
          >
            Завершить
          </ButtonComp>
          <ButtonComp
            type="button"
            onButtonClick={showModal}
            buttonStyle={{ padding: 16 }}
          >
            Сохранить фотографии
          </ButtonComp>
        </span>

        <Modal
          title={
            okClicked
              ? "Изображения сохранены!\nПопробуйте примерку в приложении"
              : "Выберите изображения для загрузки"
          }
          open={isModalOpen}
          onOk={handleOk}
          okText="Скачать"
          onCancel={handleClose}
          cancelText="Отменить"
          centered
          footer={okClicked ? null : undefined}
        >
          {okClicked ? (
            <div className="popup-description-container">
              <span className="description-text">
                Вы получите полноценную 3d-модель, на которую можно будет
                примерить бесконечное количество товаров из разных магазинов
              </span>
              <div className="description-stores-container">
                <App width="100%" height="100%" />
                <Play width="100%" height="100%" />
              </div>
              <ButtonComp
                type="button"
                onButtonClick={handleToProducts}
                isTextBtn
                buttonStyle={{ padding: 16, marginTop: 30 }}
              >
                Завершить
              </ButtonComp>
            </div>
          ) : (
            <div className="popup-container">
              {fittingData.map((imageUrl, index) => {
                return (
                  <span className="image-container" key={index}>
                    <img
                      src={imageUrl}
                      alt={`Slide ${index + 1}`}
                      className={`popup-image ${
                        selectedImages.includes(imageUrl) ? "selected" : ""
                      }`}
                      onClick={() => handleImageClick(imageUrl)}
                    />
                    {selectedImages.includes(imageUrl) ? (
                      <Check
                        className="check-icon"
                        onClick={() => handleImageClick(imageUrl)}
                      />
                    ) : (
                      <Uncheck
                        className="check-icon"
                        onClick={() => handleImageClick(imageUrl)}
                      />
                    )}
                  </span>
                );
              })}
            </div>
          )}
        </Modal>
      </div>
    </div>
  );
};

export default Fitting;
