import { Select } from "antd";
import "./style.scss";

const TwoSelects = ({
  firstData,
  secondData,
  firstValue,
  secondValue,
  firstPlaceholder,
  secondPlaceholder,
  handleChangeFirst,
  handleChangeSecond,
  isLabel,
  selectId,
  label,
}) => {
  return (
    <span className="select-container">
      {isLabel && <label htmlFor={selectId}>{label}</label>}
      <span className="several-selects-container">
        <Select
          value={firstValue}
          onChange={handleChangeFirst}
          options={firstData}
          style={{ width: "100%" }}
          placeholder={firstPlaceholder}
        />
        <Select
          value={secondValue}
          onChange={handleChangeSecond}
          options={secondData}
          style={{ width: "100%" }}
          placeholder={secondPlaceholder}
        />
      </span>
    </span>
  );
};

export default TwoSelects;
