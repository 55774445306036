import { useState } from "react";
import { EyeInvisibleOutlined, EyeOutlined } from "@ant-design/icons";
import "./style.scss";
import "../../../assets/scss/global_styles.scss";

export const Input = (props) => {
  const [passwordShown, setPasswordShown] = useState(false);
  const togglePassword = () => {
    setPasswordShown(!passwordShown);
  };
  return (
    <span className="input_container">
      {props.isLabel && (
        <label htmlFor={props.id}>{props.label ?? props.placeholder}:</label>
      )}

      <input
        className={props.error ? "input-error" : ""}
        style={{ paddingRight: props.type === "password" ? 42 : undefined }}
        type={
          props.type === "password"
            ? passwordShown
              ? "text"
              : "password"
            : props.type
        }
        id={props.id}
        name={props.name}
        value={props.value}
        placeholder={props.placeholder}
        onChange={props.handleChange}
      />
      {props.type === "password" && (
        <span className="toggle-password" onClick={togglePassword}>
          {passwordShown ? (
            <EyeOutlined className="icon" />
          ) : (
            <EyeInvisibleOutlined className="icon" />
          )}
        </span>
      )}

      {props.error && <span className="error-message">{props.error}</span>}
    </span>
  );
};
