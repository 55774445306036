import { ReactComponent as Empty } from "../../../assets/svg/empty.svg";
import "./style.scss";

export const EmptyContainer = ({ text }) => (
  <div className="page-container">
    <Empty />
    <div className="error-text-container">
      <span className="error-header">{text ?? "Упс..."}</span>
      <span className="error-text">
      Следите за обновлениями, мы скоро пополним наш магазин!
      </span>
    </div>
  </div>
);
