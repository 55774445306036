import { ReactComponent as TypeH } from "../assets/svg/figures/typeH.svg";
import { ReactComponent as TypeV } from "../assets/svg/figures/typeV.svg";
import { ReactComponent as TypeO } from "../assets/svg/figures/typeO.svg";
import { ReactComponent as TypeA } from "../assets/svg/figures/typeA.svg";
import { ReactComponent as TypeX } from "../assets/svg/figures/typeX.svg";

export const figuresData = [
  { value: "1", label: <TypeH width={'100%'} height={'100%'} /> },
  { value: "2", label: <TypeO width={'100%'} height={'100%'} /> },
  { value: "3", label: <TypeA width={'100%'} height={'100%'} /> },
  { value: "4", label: <TypeV width={'100%'} height={'100%'} /> },
  { value: "5", label: <TypeX width={'100%'} height={'100%'} /> },
];
